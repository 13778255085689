/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

interface IProps {
    viewer: any;
    isAreaModeOn: boolean | null;
    displayArea: Function;
}

export function Area(props: IProps) {
    // FYI This Cesium is a global object loaded via js file in index.html as there were compilation issues with Cesium from Npm.
    let Cesium = (window as any).Cesium;

    const [points, setPoints] = useState<any>([]);
    const [startingPointEntity, setStartingPointEntity] = useState<any>();
    const [polyLineEntity, setPolyLineEntity] = useState<any>();
    const [polygonEntity, setPolygonEntity] = useState<any>(null);
    const [sideLabels, setSideLabels] = useState<any>([]);
    const [handler, setHandler] = useState<any>(null);
    const [scene, setScene] = useState<any>(null);
    const [leftClickEnabled, setLeftClickEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (props.isAreaModeOn) {
            setLeftClickEnabled(true);
            setPolygonEntity(new Cesium.Entity());
            setScene(props.viewer.scene);
            setPolyLineEntity(new Cesium.PolylineCollection());
            setHandler(new Cesium.ScreenSpaceEventHandler(props.viewer.scene.canvas));
          
        }
        else {
            setLeftClickEnabled(false);
            clearPolygon(); // Clear previous polygon
            handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        }
    }, [props.isAreaModeOn]);


    useEffect(() => {

        if (scene != null && handler != null) {

            //left click handler
            handler.setInputAction(function (movement: any) {
                if (!leftClickEnabled) {
                    return;
                }
                var cartesian = props.viewer.scene.pickPosition(movement.position);
                if (Cesium.defined(cartesian)) {
                    points.push(cartesian.clone());
                    if (points.length === 1) {

                        setStartingPointEntity(props.viewer.entities.add({
                            position: points[0],
                            point: {
                                pixelSize: 10,
                                color: Cesium.Color.RED
                            }
                        }));
                    } else if (points.length > 1) {
                        polyLineEntity.add({
                            show: true,
                            positions: points,
                            width: 5,
                            material: new Cesium.Material({
                                fabric: {
                                    type: 'Color',
                                    uniforms: {
                                        color: Cesium.Color.RED
                                    }
                                }
                            })
                        });
                        scene.primitives.add(polyLineEntity);
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            //right click handler
            handler.setInputAction(function (movement: any) {
               // handler.setInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                var cartesian = props.viewer.scene.pickPosition(movement.position);
                if (Cesium.defined(cartesian)) {
                    points.push(cartesian.clone());
                    var area = calculatePolygonArea(points);
                    props.displayArea(area.toFixed(2) + ' SQ.M.');

                    var sideLengths = calculateSideLengths(points);
              
                    var labelPosition = points[0].clone();
                    labelPosition.y += 100;

                    polyLineEntity.add({
                        show: true,
                        positions: points,
                        width: 3,
                        material: new Cesium.Material({
                            fabric: {
                                type: 'Color',
                                uniforms: {
                                    color: Cesium.Color.RED
                                }
                            }
                        })
                    });

                    setPolygonEntity(props.viewer.entities.add({
                        polygon: {
                            hierarchy: new Cesium.PolygonHierarchy(points),
                            material: Cesium.Color.YELLOW.withAlpha(0.5)
                        }
                    }));

                    for (var i = 0; i < points.length; i++) {
                        var p1 = points[i];
                        var p2 = i === points.length - 1 ? points[0] : points[i + 1];
                        var midpoint = new Cesium.Cartesian3(
                            (p1.x + p2.x) / 2,
                            (p1.y + p2.y) / 2,
                            (p1.z + p2.z) / 2
                        );
                        var sideLabel = props.viewer.entities.add({
                            position: midpoint,
                            label: {
                                text: sideLengths[i].toFixed(2),
                                font: '16px sans-serif',
                                fillColor: Cesium.Color.BLACK,
                                outlineColor: Cesium.Color.WHITE,
                                outlineWidth: 2,
                                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                                pixelOffset: new Cesium.Cartesian2(0, -20)
                            }
                        });
                        setSideLabels((sideLabels: any) => [...sideLabels, sideLabel]);
                    }

                    setLeftClickEnabled(false);
                }
                handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        }
    }, [leftClickEnabled, handler, scene]);

    function calculatePolygonArea(points: any) {
        var area = 0;
        var n = points.length;
        for (let i = 0; i < n - 1; i++) {
            area += points[i].x * points[i + 1].y - points[i + 1].x * points[i].y;
        }
        area += points[n - 1].x * points[0].y - points[0].x * points[n - 1].y;
        return Math.abs(area / 2);
    }

    function calculateSideLengths(points: any) {
        var sides = [];
        for (var i = 0; i < points.length; i++) {
            var p1 = points[i];
            var p2 = i === points.length - 1 ? points[0] : points[i + 1];
            var sideLength = Cesium.Cartesian3.distance(p1, p2);
            sides.push(sideLength);
        }
        return sides;
    }
    function clearPolygon() {

        if (startingPointEntity) {
            props.viewer.entities.remove(startingPointEntity);
            setStartingPointEntity(null);
        }

        if (Cesium.defined(polygonEntity)) {
            props.viewer.entities.remove(polygonEntity);
            setPolygonEntity(null);
        }

        if (Cesium.defined(polyLineEntity)) {
            polyLineEntity.removeAll();
            setPolyLineEntity(null);
        }
        for (var i = 0; i < sideLabels.length; i++) {
            props.viewer.entities.remove(sideLabels[i]);
        }
       
        setPoints([]);
        setSideLabels([]);
        props.displayArea('');
        handler &&  handler.destroy();
        setHandler(null);
    }

    return (
        <div > </div>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import crossHair from '../../../images/circle.png';
import MarkerMenu from '../MarkerMenu/MarkerMenu';

interface IProps {
  viewer: any;
  isSelectionModeOn: boolean;
  isOpenInPopup: boolean;
  segmentId: number | undefined;
}

const AssetMarker = (props: IProps) => {
  const [scene, setScene] = useState<any>(props.viewer.scene);
  const [crosshairPos, setCrosshairPos] = useState<any>();
  const [mousePosition, setMousePosition] = useState<any>(null);
  const [clickLocation, setClickLocation] = useState<any | null>(null);
  const [billBoard, setBillboard] = useState<any>(null);
  const [handler, setHandler] = useState<any>(null);
  const [prevSegmentId, setPrevSegmentId] = useState<number | undefined>(undefined);

  let Cesium = (window as any).Cesium;

  const resetMenu = () => {
    setMousePosition(null);
    setClickLocation(null);
  };

  useEffect(() => {
    //clear billboard and menu everytime segment id is changed
    if (props.segmentId) {
      setPrevSegmentId(props.segmentId);
      if (prevSegmentId !== props.segmentId) {
        resetMenu();
        scene.primitives.remove(billBoard);
      }
    }
  }, [props.segmentId]);

  useEffect(() => {
    if (props.isSelectionModeOn) {
      setHandler(new Cesium.ScreenSpaceEventHandler(props.viewer.scene.canvas));
    } else {
      scene.primitives.remove(billBoard);
      resetMenu();
      handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK) && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      handler && handler.destroy();
      setHandler(null);
    }
  }, [props.isSelectionModeOn]);

  useEffect(() => {
    if (handler !== null && props.isSelectionModeOn) {
      handler.setInputAction((click: any) => {
        var cartesian = scene.pickPosition(click.position);
        setCrosshairPos(cartesian);
        resetMenu();
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

      handler.setInputAction((click: any) => {
        let pickedFeature = scene.pick(click.position);

        if (!Cesium.defined(pickedFeature) || !pickedFeature) {
          resetMenu();
          return;
        }
        //SEE IF BILLBOARD IS PICKED
        let isBillboard = pickedFeature.primitive instanceof Cesium.Billboard;
        if (isBillboard) {
          setMousePosition(click.position);
          setClickLocation(pickedFeature.primitive.position);
        } else {
          resetMenu();
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }
  }, [handler, props.isSelectionModeOn]);

  //render billboard on updated position.
  useEffect(() => {
    if (billBoard !== null) {
      scene.primitives.remove(billBoard);
      setBillboard(null);
    }

    var billboards = scene.primitives.add(new Cesium.BillboardCollection());

    billboards.add({
      id: 'crosshair',
      position: crosshairPos,
      image: crossHair,
      scale: 0.05,
    });

    setBillboard(billboards);
  }, [crosshairPos]);

  return <MarkerMenu mousePosition={mousePosition} clickLocation={clickLocation} isOpenInPopup={props.isOpenInPopup}></MarkerMenu>;
};

export default AssetMarker;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

interface IProps {
    viewer: any;
    isVolumeModeOn: boolean | null;
    displayVolume: Function;
    prompt: Function;
    initialiseFlag: number;
    model: any | null;
}

export function Volume(props: IProps) {
    // FYI This Cesium is a global object loaded via js file in index.html as there were compilation issues with Cesium from Npm.
    let Cesium = (window as any).Cesium;

    const [points, setPoints] = useState<any>([]);
    const [polyLineEntity, setPolyLineEntity] = useState<any>();
    const [depthPolyLineEntity, setDepthPolyLineEntity] = useState<any>();
    const [handler, setHandler] = useState<any>(null);
    const [scene, setScene] = useState<any>(null);
    const [isLeftClickEnabled, setIsLeftClickEnabled] = useState<boolean>(false);

    const isDepthModeEnabled = useRef<boolean>(false);
    const polygonArea = useRef<number>(0);
    
    const originalPoints = useRef<any>([]);
    const polyLineEntities = useRef<any>([]);
    const polygons = useRef<any>([]);
    const nodePoints = useRef<any>([]);
    const backNodePoints = useRef<any>([]);

    let geodesic = new Cesium.EllipsoidGeodesic();

    useEffect(() => {
        if (props.isVolumeModeOn) {
            setIsLeftClickEnabled(true);
            isDepthModeEnabled.current = false;
            setPoints([]);
            originalPoints.current = [];
            polyLineEntities.current = [];
            polygons.current = [];
            nodePoints.current = [];
            backNodePoints.current = [];
            setScene(props.viewer.scene);
            setPolyLineEntity(new Cesium.PolylineCollection());
            setDepthPolyLineEntity(new Cesium.PolylineCollection());
            setHandler(new Cesium.ScreenSpaceEventHandler(props.viewer.scene.canvas));
            props.prompt('Draw a vertical area starting at the bottom left hand side and work across, then up and around. Right click to finish');
        }
        else {
            clearUp();
        }
    }, [props.isVolumeModeOn]);

    useEffect(() => {
        clearUp();
    }, [props.initialiseFlag]);

    useEffect(() => {

        if (scene != null && handler != null) {

            //left click handler
            handler.setInputAction(function (movement: any) {
                if (!isLeftClickEnabled) {
                    return;
                }

                var cartesian = props.viewer.scene.pickPosition(movement.position);

                if (Cesium.defined(cartesian)) {

                    if (isDepthModeEnabled.current === true )
                    {
                        
                        var thisClickGeoPosition = Cesium.Cartographic.fromCartesian(cartesian);
                        var firstClickGeoPosition = Cesium.Cartographic.fromCartesian(originalPoints.current[0]);
                        
                        var distance = getDistance( firstClickGeoPosition, thisClickGeoPosition);

                        drawVolumePolygons(distance);
                    }
                    else {
                        points.push(cartesian.clone());

                        addNodePoint(points[ points.length - 1 ]);

                        if (points.length > 1) {
                           
                            drawAreaPolyline( points );
                        }
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            //right click handler
            handler.setInputAction(function (movement: any) {

                if ( points.length < 3 )
                    return;

                var cartesian = props.viewer.scene.pickPosition(movement.position);

                if (Cesium.defined(cartesian)) {

                    // Make the tiles see through so we can see the depth
                    if ( props.model )
                    {
                        props.model.style = new Cesium.Cesium3DTileStyle({
                            color: 'color("#FFFFFF", 0.999)'  
                        });
                    }

                    points.push(cartesian.clone());

                    if (!isDepthModeEnabled.current)
                        addNodePoint(points[ points.length - 1 ]);

                    drawAreaPolyline( points );

                    polygonArea.current = calculatePolygonArea(points);
                    var polygonStartingDepth = 0.5;

                    originalPoints.current = points;
                    
                    originalPoints.current.push( originalPoints.current[0] ); 
                    
                    isDepthModeEnabled.current = true;
                    props.prompt('Use left click to set the depth');

                    drawVolumePolygons( polygonStartingDepth );
                }
                handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        }
    }, [isLeftClickEnabled, handler, scene]);

    function addNodePoint(point:any){

        nodePoints.current.push(props.viewer.entities.add({
            position: point,
            point: {
                pixelSize: 10,
                color: Cesium.Color.RED
            }
        }));
    }

    function drawAreaPolyline(points:any)
    {

        polyLineEntity.add({
            show: true,
            positions: points,
            width: 2,
            material: new Cesium.Material({
                fabric: {
                    type: 'Color',
                    uniforms: {
                        color: Cesium.Color.YELLOW
                    }
                }
            })
        });
        scene.primitives.add(polyLineEntity);
    }

    function drawVolumePolygons(distance:number){

        // Remove old entities
        polyLineEntities.current.forEach((e:any) => scene.primitives.remove(e));
        polyLineEntities.current.length = 0;

        backNodePoints.current.forEach((e:any) => props.viewer.entities.remove(e));
        backNodePoints.current.length = 0;

        depthPolyLineEntity.removeAll();

        // Draw at right angle to drawn area
        var firstTwoPointsHeading = getHeading(originalPoints.current[0], originalPoints.current[1]);

        firstTwoPointsHeading -=90;
        firstTwoPointsHeading = toRadians( firstTwoPointsHeading );

        let farPoints = [];

        for (let i=0; i < originalPoints.current.length;++i )
        {
            let point1:any = originalPoints.current[i];

            var direction = new Cesium.HeadingPitchRoll(firstTwoPointsHeading, 0, 0); 

            var geoPosition = Cesium.Cartographic.fromCartesian(point1);

            var point2 = createROIfromRotation(geoPosition, direction, distance);

            farPoints.push(point2);

        };
                            
        polygons.current.forEach((poly:any) => props.viewer.entities.remove( poly ));
        polygons.current.length = 0;

        // Front
        addPolygon(originalPoints.current);

        // Back
        addPolygon(farPoints);

        // Back node points
        for( let idx = 0;idx < farPoints.length -1; idx++){
            backNodePoints.current.push(props.viewer.entities.add({
                position: farPoints[idx],
                point: {
                    pixelSize: 10,
                    color: Cesium.Color.RED
                }
            }));
        }

        // Sides 
        for( let idx = 0;idx < originalPoints.current.length -1; idx++)
        {
            let nextIdx = idx+1;
            let thesePoints = [];

            thesePoints.push(originalPoints.current[idx]);
            thesePoints.push(originalPoints.current[nextIdx]);
            thesePoints.push(farPoints[nextIdx]);
            thesePoints.push(farPoints[idx]);

            addPolygon(thesePoints);

            // Add red guide line for depth
            if ( idx === originalPoints.current.length - 2 ){
                depthPolyLineEntity.add({
                    show: true,
                    positions: [thesePoints[1],thesePoints[2]],
                    width: 2,
                    material: new Cesium.Material({
                        fabric: {
                            type: 'Color',
                            uniforms: {
                                color: Cesium.Color.RED
                            }
                        }
                    })
                });
                scene.primitives.add(depthPolyLineEntity);
            }
        }
    }

    function addPolygon(points:any){
        polygons.current.push( props.viewer.entities.add({
                   polygon: {
                       hierarchy: new Cesium.PolygonHierarchy(points),
                       material: Cesium.Color.YELLOW.withAlpha(0.5),
                       perPositionHeight: true,
                   }
               }));
    }

    function toRadians(degrees:number) {
      return degrees * Math.PI / 180;
    }

    function getDistance( point1:any, point2:any) {
        geodesic.setEndPoints(point1, point2);
        var horizontalMeters = geodesic.surfaceDistance.toFixed(2);
        var heights = [point1.height, point2.height];
        var verticalMeters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
        var hypotenuse = Math.pow((Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2)), 0.5);

        return hypotenuse;
    }

    function getHeading( point1:any, point2:any){
        const transform=Cesium.Transforms.eastNorthUpToFixedFrame(point1);
        const positionvector=Cesium.Cartesian3.subtract(point2,point1,new Cesium.Cartesian3());
        const vector=Cesium.Matrix4.multiplyByPointAsVector(Cesium.Matrix4.inverse(transform,new Cesium.Matrix4()),positionvector,new Cesium.Cartesian3());
        const direction=Cesium.Cartesian3.normalize(vector,new Cesium.Cartesian3());
        const heading=Math.atan2(direction.y,direction.x)-Cesium.Math.PI_OVER_TWO;
        return Cesium.Math.toDegrees(Cesium.Math.TWO_PI-Cesium.Math.zeroToTwoPi(heading));
    }

    function createROIfromRotation(position:any,  rotation:any, length:any) {
      // position: Cartographic - {latitude, longitude, altitude})
      // rotation: HeadingPitchRoll - {heading, pitch, roll}

      // Based on answer found here:
      // https://stackoverflow.com/questions/58021985/create-a-point-in-a-direction-in-cesiumjs

       var cartesianPosition = Cesium.Ellipsoid.WGS84.cartographicToCartesian(position);

        rotation.heading = rotation.heading - Cesium.Math.toRadians(90);
        var referenceFrame1 = Cesium.Transforms.headingPitchRollQuaternion(cartesianPosition, rotation);
        var rotationMatrix = Cesium.Matrix3.fromQuaternion(referenceFrame1, new Cesium.Matrix3());
        var rotationScaled = Cesium.Matrix3.multiplyByVector(rotationMatrix, new Cesium.Cartesian3(length, 0, 0), new Cesium.Cartesian3());
        var roiPos = Cesium.Cartesian3.add(cartesianPosition, rotationScaled, new Cesium.Cartesian3());
        return roiPos;
    }

    function calculatePolygonArea(points: any) {
        var area = 0;
        var n = points.length;
        for (let i = 0; i < n - 1; i++) {
            area += points[i].x * points[i + 1].y - points[i + 1].x * points[i].y;
        }
        area += points[n - 1].x * points[0].y - points[0].x * points[n - 1].y;
        return Math.abs(area / 2);
    }

    function clearUp() {

        for(let i=0; i < nodePoints.current.length; ++i )
        {
            props.viewer.entities.remove(nodePoints.current[i]);
        }

        for(let i=0; i < backNodePoints.current.length; ++i )
        {
            props.viewer.entities.remove(backNodePoints.current[i]);
        }

        if (Cesium.defined(polyLineEntity)) {
            polyLineEntity.removeAll();
            setPolyLineEntity(null);
        }

        if (Cesium.defined(depthPolyLineEntity)) {
            depthPolyLineEntity.removeAll();
            setDepthPolyLineEntity(null);
        }

        if ( props.model ){
            props.model.style = null;
        }

        polygons.current.forEach((poly:any) => props.viewer.entities.remove( poly ));
        setPoints([]);
        props.displayVolume('');

        handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        handler &&  handler.destroy();
        setHandler(null);

        props.prompt('');
        setIsLeftClickEnabled(false);
        isDepthModeEnabled.current = false;
        polyLineEntities.current.forEach((e:any) => scene.primitives.remove(e));
    }

    return (
        <div > </div>
    );
}
